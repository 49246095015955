<template>
  <v-container>
    <v-row>
      <v-col :class="titleClass">{{
        t('Title_.subTitle.vehicleDetails')
      }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          data-testid="fleet-vehicle-details-plate-input"
          v-model="fleetVehiclesStore.vehicleDetails.plate"
          @change="fleetVehiclesStore.vehicleDetails.plate.toUpperCase()"
          :label="t('Msg_.vehiclePlate') + (isDisabled ? '' : '*')"
          maxlength="10"
          :rules="[
            rules.fieldRequired(
              `${t('Msg_.vehiclePlate')} ${t('Msg_.rules.isRequired')}`
            ),
            rules.vehiclePlate(t('Msg_.rules.vehiclePlateWrongFormat')),
          ]"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          data-testid="fleet-vehicle-details-fuel-card-input"
          v-model="fleetVehiclesStore.vehicleDetails.fuelCard"
          :label="t('Msg_.fuelCard')"
          maxlength="17"
          :rules="[
            rules.totalSize(17, t('Msg_.rules.totalDigits', { total: '17' })),
            rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
          ]"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DropdownInput
          data-testid="fleet-vehicle-details-hub-dropdown"
          :label="t('Msg_.office') + (isDisabled ? '' : '*')"
          :items="hubList"
          item-text="name"
          item-value="id"
          v-model="fleetVehiclesStore.vehicleDetails.hubId"
          :clearable="!isDisabled"
          :disabled="isDisabled"
          :rules="[
            rules.fieldRequired(
              `${t('Msg_.office')} ${t('Msg_.rules.isRequired')}`
            ),
          ]"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DropdownInput
          data-testid="fleet-vehicle-details-usage-dropdown"
          :label="t('Msg_.usage')"
          :items="fleetVehiclesStore.usageList"
          item-text="description"
          item-value="id"
          v-model="fleetVehiclesStore.vehicleDetails.usageId"
          :clearable="!isDisabled"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DropdownInput
          data-testid="fleet-vehicle-details-owner-dropdown"
          :label="t('Msg_.owner') + (isDisabled ? '' : '*')"
          :items="fleetVehiclesStore.ownersList"
          item-text="description"
          item-value="id"
          v-model="fleetVehiclesStore.vehicleDetails.ownerId"
          :clearable="!isDisabled"
          :disabled="isDisabled"
          :rules="[
            rules.fieldRequired(
              `${t('Msg_.owner')} ${t('Msg_.rules.isRequired')}`
            ),
          ]"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DropdownInput
          data-testid="fleet-vehicle-details-type-acquirement-dropdown"
          :label="t('Msg_.typeOfAcquirement')"
          :items="selectTypeOfAcquirement"
          :value="fleetVehiclesStore.vehicleDetails.acquirementType"
          :clearable="!isDisabled"
          :disabled="isDisabled"
          @change="changeAcquirementType($event)"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          data-testid="fleet-vehicle-details-base-price-input"
          prefix="€"
          :value="basePriceValue"
          @change="
            updateCurrencyField(
              'basePrice',
              helpers.convertValueToCurrency($event)
            )
          "
          @focus="
            updateCurrencyField(
              'basePrice',
              helpers.convertCurrencyToFloat($event)
            )
          "
          :label="t('Msg_.basePrice') + (isDisabled ? '' : '*')"
          :maxlength="'12'"
          :disabled="isDisabled"
          :rules="
            isDisabled
              ? []
              : [
                  rules.fieldRequired(
                    `${t('Msg_.basePrice')} ${t('Msg_.rules.isRequired')}`
                  ),

                  rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                ]
          "
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          v-model="fleetVehiclesStore.vehicleDetails.currentKm"
          :label="t('Msg_.kmStand')"
          maxlength="6"
          :disabled="isDisabled"
          :rules="
            isDisabled ? [] : [rules.onlyNumbers(t('Msg_.rules.onlyNumbers'))]
          "
          @change="formatCurrentKm"
          data-testid="fleet-vehicle-details-current-km-input"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from '../locales/languages';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { defineProps, computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const updateCurrencyField = (field, value) => {
  fleetVehiclesStore.vehicleDetails[field] = value;
};

const formatCurrentKm = (val) => {
  fleetVehiclesStore.vehicleDetails.currentKm = parseInt(val) || null;
};

const changeAcquirementType = (val) => {
  const previousAcquirementType =
    fleetVehiclesStore.vehicleDetails.acquirementType;

  if (previousAcquirementType) {
    const previousAcquirementTypeObject =
      fleetVehiclesStore.vehicleResources.acquirementTypeValues.find(
        (acquirementType) => acquirementType.Value === previousAcquirementType
      );

    if (previousAcquirementTypeObject.Name === 'Bought') {
      clearPurchaseAndInsuranceInformation();
    } else {
      clearContractInformation();
    }
  }
  fleetVehiclesStore.vehicleDetails.acquirementType = val;
};

const clearPurchaseAndInsuranceInformation = () => {
  const purchaseInformation = ['updatedCarValue', 'carRevenue'];
  clearVehicleDetailsProperties(purchaseInformation);

  const insuranceInformation = [
    'insuranceProvider',
    'insuranceRenewalDate',
    'insuranceType',
    'insuranceDeductible',
  ];
  clearVehicleDetailsProperties(insuranceInformation);
};

const clearContractInformation = () => {
  const contractInformation = [
    'bankGuarantee',
    'carReturned',
    'carReturnedDate',
    'contractDate',
    'contractNumber',
    'contractualPartnerId',
    'endOfLeasing',
    'extraPayment',
    'kmAllowance',
    'monthlyInstallment',
  ];
  clearVehicleDetailsProperties(contractInformation);
};

const clearVehicleDetailsProperties = (properties) => {
  properties.forEach((property) => {
    fleetVehiclesStore.vehicleDetails[property] = null;
  });
};

// --- Computed ---
const hubList = computed(() => {
  return fleetVehiclesStore.hubList;
});

const selectTypeOfAcquirement = computed(() => {
  let actionSelectTypeOfAcquirement = tm('Action_.typeOfAcquirement');
  return helpers.translateAndAdaptToVuetify(
    actionSelectTypeOfAcquirement,
    fleetVehiclesStore.vehicleResources.acquirementTypeValues
  );
});

const titleClass = computed(() => {
  return props.isDisabled ? 'section-title pb-6' : 'section-title';
});

const formFieldsClass = computed(() => {
  return props.isDisabled ? 'py-0' : '';
});

const basePriceValue = computed(() => {
  return props.isDisabled
    ? helpers.convertValueToCurrency(
        fleetVehiclesStore.vehicleDetails.basePrice
      )
    : fleetVehiclesStore.vehicleDetails.basePrice;
});
</script>

<style scoped lang="scss">
.v-text-field ::v-deep label {
  font-size: 13px;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
