<template>
  <v-container class="pa-0" data-testid="fleet-vehicle-details">
    <v-row>
      <v-col>
        <v-card class="rounded-lg py-3 px-4 shadow-regular">
          <VehicleDetails :value="vehicleDetails" is-disabled />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import VehicleDetails from '../vehicleForm/vehicle-details.vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import helpers from '@/helpers/helpers';
import { ref } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();

// --- Local variables ---
const vehicleDetails = ref({
  plate: null,
  fuelCard: null,
  hubId: 0,
  type: null,
  fuelType: null,
});

// --- Methods ---
const populateObjects = () => {
  vehicleDetails.value = helpers.fillObjectFromAnotherObject(
    vehicleDetails.value,
    fleetVehiclesStore.vehicleDetails
  );
};

// --- Lifecycle hooks ---
populateObjects();
</script>

<style scoped lang="scss"></style>
