var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{class:_setup.titleClass},[_vm._v(_vm._s(_setup.t('Title_.subTitle.vehicleDetails')))])],1),_c('v-row',[_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"fleet-vehicle-details-plate-input","label":_setup.t('Msg_.vehiclePlate') + (_vm.isDisabled ? '' : '*'),"maxlength":"10","rules":[
          _setup.rules.fieldRequired(
            `${_setup.t('Msg_.vehiclePlate')} ${_setup.t('Msg_.rules.isRequired')}`
          ),
          _setup.rules.vehiclePlate(_setup.t('Msg_.rules.vehiclePlateWrongFormat')),
        ],"disabled":_vm.isDisabled},on:{"change":function($event){return _setup.fleetVehiclesStore.vehicleDetails.plate.toUpperCase()}},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.plate),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "plate", $$v)},expression:"fleetVehiclesStore.vehicleDetails.plate"}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"fleet-vehicle-details-fuel-card-input","label":_setup.t('Msg_.fuelCard'),"maxlength":"17","rules":[
          _setup.rules.totalSize(17, _setup.t('Msg_.rules.totalDigits', { total: '17' })),
          _setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers')),
        ],"disabled":_vm.isDisabled},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.fuelCard),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "fuelCard", $$v)},expression:"fleetVehiclesStore.vehicleDetails.fuelCard"}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"fleet-vehicle-details-hub-dropdown","label":_setup.t('Msg_.office') + (_vm.isDisabled ? '' : '*'),"items":_setup.hubList,"item-text":"name","item-value":"id","clearable":!_vm.isDisabled,"disabled":_vm.isDisabled,"rules":[
          _setup.rules.fieldRequired(
            `${_setup.t('Msg_.office')} ${_setup.t('Msg_.rules.isRequired')}`
          ),
        ]},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.hubId),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "hubId", $$v)},expression:"fleetVehiclesStore.vehicleDetails.hubId"}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"fleet-vehicle-details-usage-dropdown","label":_setup.t('Msg_.usage'),"items":_setup.fleetVehiclesStore.usageList,"item-text":"description","item-value":"id","clearable":!_vm.isDisabled,"disabled":_vm.isDisabled},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.usageId),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "usageId", $$v)},expression:"fleetVehiclesStore.vehicleDetails.usageId"}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"fleet-vehicle-details-owner-dropdown","label":_setup.t('Msg_.owner') + (_vm.isDisabled ? '' : '*'),"items":_setup.fleetVehiclesStore.ownersList,"item-text":"description","item-value":"id","clearable":!_vm.isDisabled,"disabled":_vm.isDisabled,"rules":[
          _setup.rules.fieldRequired(
            `${_setup.t('Msg_.owner')} ${_setup.t('Msg_.rules.isRequired')}`
          ),
        ]},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.ownerId),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "ownerId", $$v)},expression:"fleetVehiclesStore.vehicleDetails.ownerId"}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"fleet-vehicle-details-type-acquirement-dropdown","label":_setup.t('Msg_.typeOfAcquirement'),"items":_setup.selectTypeOfAcquirement,"value":_setup.fleetVehiclesStore.vehicleDetails.acquirementType,"clearable":!_vm.isDisabled,"disabled":_vm.isDisabled},on:{"change":function($event){return _setup.changeAcquirementType($event)}}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"fleet-vehicle-details-base-price-input","prefix":"€","value":_setup.basePriceValue,"label":_setup.t('Msg_.basePrice') + (_vm.isDisabled ? '' : '*'),"maxlength":'12',"disabled":_vm.isDisabled,"rules":_vm.isDisabled
            ? []
            : [
                _setup.rules.fieldRequired(
                  `${_setup.t('Msg_.basePrice')} ${_setup.t('Msg_.rules.isRequired')}`
                ),

                _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
              ]},on:{"change":function($event){_setup.updateCurrencyField(
            'basePrice',
            _setup.helpers.convertValueToCurrency($event)
          )},"focus":function($event){_setup.updateCurrencyField(
            'basePrice',
            _setup.helpers.convertCurrencyToFloat($event)
          )}}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.kmStand'),"maxlength":"6","disabled":_vm.isDisabled,"rules":_vm.isDisabled ? [] : [_setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers'))],"data-testid":"fleet-vehicle-details-current-km-input"},on:{"change":_setup.formatCurrentKm},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.currentKm),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "currentKm", $$v)},expression:"fleetVehiclesStore.vehicleDetails.currentKm"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }